/* eslint-disable no-console */
import type { IAnalyticsAdapter } from '@/shared/lib/analytics'
import { cookies } from '@/shared/lib/cookies'

import { appFbdataPost } from './appFbdataPost.api'
import { appLogEventPost } from './appLogEventPost.api'
import type {
  FacebookEvent,
  FacebookEventParameters,
  FacebookEventOptions,
  FacebookInitData,
} from './facebook.types'

const PARAM_FACEBOOK_FBP = '_fbp'
const PARAM_FACEBOOK_FBC = '_fbc'

export class FacebookAdapter implements IAnalyticsAdapter {
  protected _checkAccess: () => boolean
  protected _isInit: boolean = false
  protected _logging: boolean = false
  protected _appId: string | undefined
  protected _seq: number = 0
  protected _windowId: string | undefined
  protected _name: string | undefined
  protected _version: string | undefined
  protected _deviceId: string | undefined

  constructor(
    checkAccess: () => boolean,
    logging: boolean,
    windowId: string,
    deviceId: string,
    appId: string,
  ) {
    this._checkAccess = checkAccess
    this._logging = logging
    this._windowId = windowId
    this._deviceId = deviceId
    this._appId = appId
  }

  init(): void {
    if (!this._appId || !this._checkAccess()) return

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    // @ts-ignore
    window.fbAsyncInit = () => {
      // @ts-ignore
      FB.init({
        appId: self._appId,
        xfbml: true,
        version: 'v19.0',
      })
      self._isInit = true
    }

    const script = document.createElement('script')
    script.src = `https://connect.facebook.net/en_US/sdk.js`
    script.async = true
    script.defer = true
    script.crossOrigin = 'anonymous'
    document.head.appendChild(script)
  }

  public login(): void {}

  public signUp(): void {}

  public userId(): void {}

  public userProps(): void {}

  public event(): void {}

  public facebookEvent(
    event: FacebookEvent,
    data: FacebookEventParameters,
    options?: FacebookEventOptions,
  ): void {
    if (!this._isInit) return
    if (this._logging) console.log('Facebook event: %s', event, data, options)

    if (options) {
      // @ts-ignore
      FB.fbq('track', event, data, options)
    } else {
      // @ts-ignore
      FB.track(event, data)
    }
    try {
      appLogEventPost(event, {
        ...data,
        ...options,
      })
    } catch (err) {
      console.error(err)
    }
  }

  // https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching/
  public eventInit(data: FacebookInitData): void {
    if (!this._isInit) return
    if (this._logging) console.log('Facebook init:', data)
    // @ts-ignore
    FB.fbq('init', this._appId, data)
  }

  public sendFbcFba() {
    appFbdataPost(cookies.get(PARAM_FACEBOOK_FBP), cookies.get(PARAM_FACEBOOK_FBC))
  }
}
