import * as Sentry from '@sentry/vue'

import { env } from '@/shared/config'
import { deviceIdGet } from '@/shared/lib/deviceId'
import { sessionIdGet } from '@/shared/lib/sessionId'
import { windowIdGet } from '@/shared/lib/windowId'

const ANALYTICS_NAME = 'sentry'

const cache = {
  loading: false,
  Sentry: undefined as typeof Sentry | undefined,
}

// https://www.lichter.io/articles/nuxt3-sentry-recipe/#nuxt-integration-setting-up-the-client-side
export default defineNuxtPlugin({
  parallel: true,
  setup(nuxtApp) {
    const router = useRouter()
    const sampleRate = env.IS_PROD ? 0.05 : 1

    if (!env.SENTRY_DNS) return

    // Sentry.init({
    //   enabled: env.IS_PROD || env.IS_STAGE,
    //   release: env.PROJECT_NAME + '@' + env.VERSION + (env.IS_DEV ? '-dev' : ''),
    //   app: nuxtApp.vueApp,
    //   dsn: env.SENTRY_DNS,
    //   environment: env.APP_ENV,
    //   integrations: [
    //     Sentry.browserTracingIntegration({ router }),
    //     Sentry.replayIntegration({
    //       maskAllText: false,
    //       blockAllMedia: false,
    //     }),
    //   ],

    //   // Configure this whole part as you need it!
    //   tracesSampleRate: sampleRate, // Change in prod

    //   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    //   tracePropagationTargets: env.SENTRY_TRACE_HOSTS
    //     ? env.SENTRY_TRACE_HOSTS.split(',')
    //     : undefined,

    //   replaysSessionSampleRate: sampleRate, // Change in prod
    //   replaysOnErrorSampleRate: sampleRate, // Change in prod if necessary

    //   maxBreadcrumbs: 300,
    //   attachStacktrace: true,
    // })

    // Sentry.setContext('app', {
    //   deviceId: deviceIdGet(),
    //   windowId: windowIdGet(),
    //   sessionId: sessionIdGet(),
    // })

    async function init() {
      if (cache.loading) return
      cache.loading = true
      cache.Sentry = await import('@sentry/vue')

      cache.Sentry.init({
        enabled: env.IS_PROD || env.IS_STAGE,
        release: env.PROJECT_NAME + '@' + env.VERSION + (env.IS_DEV ? '-dev' : ''),
        app: nuxtApp.vueApp,
        dsn: env.SENTRY_DNS,
        environment: env.APP_ENV,
        integrations: [
          cache.Sentry.browserTracingIntegration({ router }),
          cache.Sentry.browserProfilingIntegration(),
          cache.Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],

        // Configure this whole part as you need it!
        tracesSampleRate: sampleRate, // Change in prod

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: env.SENTRY_TRACE_HOSTS
          ? env.SENTRY_TRACE_HOSTS.split(',')
          : undefined,

        replaysSessionSampleRate: sampleRate, // Change in prod
        replaysOnErrorSampleRate: sampleRate, // Change in prod if necessary

        maxBreadcrumbs: 300,
        attachStacktrace: true,
      })
      cache.Sentry.setContext('app', {
        deviceId: deviceIdGet(),
        windowId: windowIdGet(),
        sessionId: sessionIdGet(),
      })
    }

    router.afterEach((toRoute) => {
      if (toRoute.path === '/') return
      init()
    })
    if (location.pathname === '/') return
    init()

    return {
      provide: {
        // [ANALYTICS_NAME]: Sentry,
        [ANALYTICS_NAME]: {
          captureException: (error: Error) => cache.Sentry?.captureException(error),
          captureMessage: (message: string) => cache.Sentry?.captureMessage(message),
          setUser: (userId: number) => cache.Sentry?.setUser({ id: String(userId) }),
        },
      },
    }
  },
})
