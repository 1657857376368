let disabledSend = false

export function init() {
  // @ts-ignore
  window.dataLayer = window.dataLayer || []
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out?hl=en
export function disableTrack(disabled: boolean) {
  disabledSend = disabled
}

// https://developers.google.com/tag-platform/gtagjs/reference
const gtag: Function = function gtag() {
  // @ts-ignore
  if (!window.dataLayer || disabledSend) return
  // @ts-ignore,
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments)
}

export { gtag }
