<script setup>
useHead({
  htmlAttrs: {
    translate: 'no',
  },
})

let subscription = null

onMounted(() => {
  subscription = window.addEventListener('resize', parseUnits)
  parseUnits()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', subscription)
})

const parseUnits = () => {
  const cvh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${cvh}px`)
  document.documentElement.style.setProperty('--cvh', `${cvh}px`)
}
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
