import { env } from '@/shared/config'
import { AmplitudeAdapter } from '@/shared/lib/amplitude'
import { Analytics } from '@/shared/lib/analytics'
import { cookiesAccess } from '@/shared/lib/cookiesAccess'
import { deviceIdGet } from '@/shared/lib/deviceId'
import { windowIdGet } from '@/shared/lib/windowId'

const ANALYTICS_NAME = 'amplitude'
const COOKIES_ACCESS_LEVEL = 'analytics'

export default defineNuxtPlugin({
  parallel: true,
  setup() {
    const adapter = new AmplitudeAdapter(
      () => cookiesAccess.canInit(COOKIES_ACCESS_LEVEL),
      env.IS_DEV || env.IS_STAGE,
      windowIdGet(),
      env.PROJECT_NAME,
      env.VERSION,
      deviceIdGet(),
      env.AMPLITUDE_API_KEY,
      env.APPLICATION
    )
    Analytics.clientSet(ANALYTICS_NAME, adapter)
    Analytics.clientGet(ANALYTICS_NAME)?.init()

    return {
      provide: {
        [ANALYTICS_NAME]: {
          revenue: adapter.revenue,
        },
      },
    }
  },
})
