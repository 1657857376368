import { env } from '@/shared/config'
import { Analytics } from '@/shared/lib/analytics'
import { windowIdGet } from '@/shared/lib/windowId'

export default defineNuxtPlugin({
  name: 'analytics',
  setup() {
    const analytics = Analytics.create(!env.IS_PROD, windowIdGet())
    return {
      provide: {
        analytics,
      },
    }
  },
})
