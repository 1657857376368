type ApiResponse = {
  success: boolean
}

const API_REQUEST_PATH = '/app/log-event'

export function appLogEventPost(event: string, data: any = {}) {
  const formData = {
    fb_event: {
      event,
      data,
    },
  }
  return useApiApp<ApiResponse>(API_REQUEST_PATH, 'POST', formData)
}
