type ApiResponse = {
  success: boolean
}

const API_REQUEST_PATH = '/app/fbdata'

export function appFbdataPost(fbp?: string, fbc?: string) {
  const formData = {
    fbp,
    fbc,
  }
  return useApiApp<ApiResponse>(API_REQUEST_PATH, 'POST', formData)
}
