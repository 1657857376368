export const appHead = {"meta":[{"charset":"utf-8"},{"name":"google","content":"notranslate"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"},{"name":"apple-mobile-web-app-capable","content":"yes"},{"name":"mobile-web-app-capable","content":"yes"},{"hid":"description","name":"description","content":"Discover Mili — live video chat app. Thousands of real and verified people from around the world are online and waiting for you. Start video chat now!"},{"property":"og:type","content":"website"},{"property":"og:site_name","content":"mili.live"},{"property":"og:title","content":"Mili - #1 Video Chat to Meet New Friends"},{"property":"og:description","content":"Thousands of real and verified people from around the world are online and waiting for you. Start video chat now!"},{"property":"og:url","content":"https://mili.live"},{"property":"og:image","content":"https://land.mili.live/og-image.jpg"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[],"noscript":[],"title":"#1 Video Chat to Meet New Friends","head":{"htmlAttrs":{"lang":"en"}}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000