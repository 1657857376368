import type * as amplitude from '@amplitude/analytics-browser'

import type {
  IAnalyticsAdapter,
  AnalyticAuthMethod,
  AnalyticUserProps,
} from '@/shared/lib/analytics'

let _amplitudeSDK: typeof amplitude | undefined

export class AmplitudeAdapter implements IAnalyticsAdapter {
  protected _checkAccess: () => boolean
  protected _isInit: boolean = false
  protected _logging: boolean = false
  protected _amplitude: amplitude.Types.BrowserClient | undefined
  protected _seq: number = 0
  protected _windowId: string | undefined
  protected _name: string | undefined
  protected _version: string | undefined
  protected _deviceId: string | undefined
  protected _apiKey: string | undefined
  protected _application: string | undefined

  constructor(
    checkAccess: () => boolean,
    logging: boolean,
    windowId: string,
    name: string,
    version: string,
    deviceId: string,
    apiKey: string,
    application: string,
  ) {
    this._checkAccess = checkAccess
    this._logging = logging
    this._windowId = windowId
    this._name = name
    this._version = version
    this._deviceId = deviceId
    this._apiKey = apiKey
    this._application = application
  }

  async init() {
    if (!this._apiKey || !this._checkAccess()) return
    const config = {
      defaultTracking: {
        pageViews: false,
      },
      appVersion: `${this._name}@${this._version}`,
      deviceId: this._deviceId,
    }
    // @ts-ignore
    if (navigator.sendBeacon) config.transport = 'beacon'

    _amplitudeSDK = await import('@amplitude/analytics-browser')

    this._amplitude = _amplitudeSDK.createInstance()
    await this._amplitude.init(this._apiKey, config)

    const identifyEvent = new _amplitudeSDK.Identify()
    if (this._application) identifyEvent.set('application', this._application)
    identifyEvent.set('platform', 'desktop')
    await this._amplitude.identify(identifyEvent)

    this._isInit = true
  }

  public login(method: AnalyticAuthMethod): void {
    this.userProps({
      login_source: method,
    })
  }

  public signUp(method: AnalyticAuthMethod): void {
    this.userProps({
      reg_source: method,
    })
  }

  public userId(userId: string): void {
    if (!this._isInit || !this._amplitude) return
    this._amplitude.setUserId(userId)
  }

  public userProps(props: AnalyticUserProps): void {
    if (!this._isInit || !this._amplitude || !_amplitudeSDK) return
    const identifyEvent = new _amplitudeSDK.Identify()
    Object.keys(props).forEach((key) => {
      identifyEvent.set(key, props[key] as string)
    })
    this._amplitude.identify(identifyEvent)
  }

  public event(event: string, data?: AnalyticUserProps): void {
    if (!this._isInit || !this._amplitude) return
    const amplitudeData = Object.assign({}, data, {
      window: this._windowId,
      windowSeq: this._seq++,
    })
    this._amplitude.logEvent(event, amplitudeData)
    // eslint-disable-next-line no-console
    if (this._logging) console.log('Amplitude event: %s', event, amplitudeData)
    this._amplitude.flush()
  }

  public revenue(price: number, productId: string = 'mili_web', quantity: number = 1): void {
    if (!this._isInit || !this._amplitude || !_amplitudeSDK) return
    const revenueEvent = new _amplitudeSDK.Revenue()
      .setProductId(productId)
      .setPrice(price)
      .setQuantity(quantity)
      .setRevenueType('purchase')
    this._amplitude.revenue(revenueEvent)
  }

  public flush(): void {
    if (!this._isInit || !this._amplitude) return
    this._amplitude.flush()
  }
}
