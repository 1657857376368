/* eslint-disable no-console */
import { Analytics } from './analytics.public'
import type { IAnalyticsService, AnalyticAuthMethod, AnalyticUserProps } from './analytics.types'

export class AnalyticsService implements IAnalyticsService {
  protected _windowId: string
  protected _logging: boolean

  constructor(logging: boolean, windowId: string) {
    this._windowId = windowId
    this._logging = logging
  }

  public init(): void {
    Analytics.clients().forEach((client) => client.init())
  }

  public login(method: AnalyticAuthMethod): void {
    Analytics.clients().forEach((client) => client.login(method))
  }

  public signUp(method: AnalyticAuthMethod): void {
    Analytics.clients().forEach((client) => client.signUp(method))
  }

  public userId(userId: string): void {
    if (this._logging) console.log('AnalyticsService: userId = %s', userId)
    Analytics.clients().forEach((client) => client.userId(userId))
  }

  public userPropsGet(props: Partial<AnalyticUserProps> = {}): AnalyticUserProps {
    return {
      ...props,
    }
  }

  public userProps(props: AnalyticUserProps): void {
    if (this._logging) {
      console.group('AnalyticsService: userProp')
      Object.keys(props).forEach((name: string) =>
        console.log('%s: %s', name, props[name as keyof AnalyticUserProps]),
      )
      console.groupEnd()
    }
    Analytics.clients().forEach((client) => client.userProps(props))
  }

  public event(event: string, data?: AnalyticUserProps): void {
    if (this._logging) console.group('AnalyticsService: ' + event)
    Analytics.clients().forEach((client) => client.event(event, data))
    if (this._logging) console.groupEnd()
  }

  public flush(): void {
    Analytics.clients().forEach((client) => {
      if (client?.flush) client.flush()
    })
  }
}
