import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _0_analytics_plugin_client_PY8W6GJF4x from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/shared/plugins/0.analytics.plugin.client.ts";
import _0_cookiesAccess_plugin_client_r5XnciWVyO from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/shared/plugins/0.cookiesAccess.plugin.client.ts";
import _0_sentry_plugin_client_yLLhclo1F6 from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/shared/plugins/0.sentry.plugin.client.ts";
import _0_trailingSlash_plugin_client_I6U8b2CVOO from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/shared/plugins/0.trailingSlash.plugin.client.ts";
import _1_gtag_plugin_client_KJ0FwVF9Vw from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/shared/plugins/1.gtag.plugin.client.ts";
import _2_amplitude_plugin_client_sFdngQcGY3 from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/shared/plugins/2.amplitude.plugin.client.ts";
import _2_facebook_plugin_client_wkdTCyD7It from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/shared/plugins/2.facebook.plugin.client.ts";
import _2_googleAnalytics_plugin_client_5JBon55pQG from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/shared/plugins/2.googleAnalytics.plugin.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  _0_analytics_plugin_client_PY8W6GJF4x,
  _0_cookiesAccess_plugin_client_r5XnciWVyO,
  _0_sentry_plugin_client_yLLhclo1F6,
  _0_trailingSlash_plugin_client_I6U8b2CVOO,
  _1_gtag_plugin_client_KJ0FwVF9Vw,
  _2_amplitude_plugin_client_sFdngQcGY3,
  _2_facebook_plugin_client_wkdTCyD7It,
  _2_googleAnalytics_plugin_client_5JBon55pQG
]