import { env } from '@/shared/config'
import { Analytics } from '@/shared/lib/analytics'
import { cookiesAccess } from '@/shared/lib/cookiesAccess'
import { deviceIdGet } from '@/shared/lib/deviceId'
import { GoogleAnalyticsAdapter } from '@/shared/lib/gtag'
import { windowIdGet } from '@/shared/lib/windowId'

const ANALYTICS_NAME = 'googleAnalytics'
const COOKIES_ACCESS_LEVEL = 'analytics'

export default defineNuxtPlugin({
  parallel: true,
  setup() {
    const adapter = new GoogleAnalyticsAdapter(
      () => cookiesAccess.canInit(COOKIES_ACCESS_LEVEL),
      env.IS_DEV || env.IS_STAGE,
      windowIdGet(),
      deviceIdGet(),
      env.GOOGLE_MEASUREMENT_ID,
    )
    Analytics.clientSet(ANALYTICS_NAME, adapter)
    Analytics.clientGet(ANALYTICS_NAME)?.init()

    return {
      provide: {
        [ANALYTICS_NAME]: {
          purchase: adapter.purchase,
        },
      },
    }
  },
})
