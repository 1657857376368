import { default as create_45new_45passwordQG5wqeXhBBMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/create-new-password.vue?macro=true";
import { default as email_45confirmedfFfzSXmoq3Meta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/email-confirmed.vue?macro=true";
import { default as emailQVmJ8qHIGtMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/email.vue?macro=true";
import { default as forgot_45passwordRTs0h2K6p4Meta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/forgot-password.vue?macro=true";
import { default as login_45email0yGzZxOn6YMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/login-email.vue?macro=true";
import { default as recovery_45passwordDJms5f4p5fMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/recovery-password.vue?macro=true";
import { default as registration_45email6nMzVSGThUMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/registration-email.vue?macro=true";
import { default as registration_45gender4Ursnhzv7nMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/registration-gender.vue?macro=true";
import { default as registration_45namea5a8KGSpVpMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/registration-name.vue?macro=true";
import { default as video_45chat8kISj8y9i6Meta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/video-chat.vue?macro=true";
import { default as welcomeeJIVS6hoWlMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/welcome.vue?macro=true";
import { default as bannedOWik3wfWuCMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/banned.vue?macro=true";
import { default as blocklistvJ3SAWcvICMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab/blocklist.vue?macro=true";
import { default as chatjWIumrV6rAMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab/chat.vue?macro=true";
import { default as invoice_45history5y9tvmIcOtMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab/invoice-history.vue?macro=true";
import { default as private_45callDw71p54duZMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab/private-call.vue?macro=true";
import { default as profile_45settingsTNczpTLQ0zMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab/profile-settings.vue?macro=true";
import { default as profileTHY4XJrRHSMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab/profile.vue?macro=true";
import { default as storiesQCk2VpBziaMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab/stories.vue?macro=true";
import { default as top_45profilesPLOG9n3H8QMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab/top-profiles.vue?macro=true";
import { default as video_45chatmstEgBZ5uzMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab/video-chat.vue?macro=true";
import { default as cabIbx7WyYTWjMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab.vue?macro=true";
import { default as cookie_45policycuuHWEujZxMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cookie-policy.vue?macro=true";
import { default as do_45not_45sell_45my_45personal_45infoLsnft76vJJMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/do-not-sell-my-personal-info.vue?macro=true";
import { default as double_45connectionJexojijAz6Meta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/double-connection.vue?macro=true";
import { default as indexIjwXdh5csFMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/index.vue?macro=true";
import { default as privacy_45policyaIm85VuJSQMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/privacy-policy.vue?macro=true";
import { default as purchase_45termszmxKf3Kv84Meta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/purchase-terms.vue?macro=true";
import { default as subscription_45termsn8esBMNResMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/subscription-terms.vue?macro=true";
import { default as terms_45and_45conditionswPBFmIijIkMeta } from "/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/terms-and-conditions.vue?macro=true";
export default [
  {
    name: "auth-create-new-password",
    path: "/auth/create-new-password",
    meta: create_45new_45passwordQG5wqeXhBBMeta || {},
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/create-new-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-email-confirmed",
    path: "/auth/email-confirmed",
    meta: email_45confirmedfFfzSXmoq3Meta || {},
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/email-confirmed.vue").then(m => m.default || m)
  },
  {
    name: "auth-email",
    path: "/auth/email",
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/email.vue").then(m => m.default || m)
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordRTs0h2K6p4Meta || {},
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-login-email",
    path: "/auth/login-email",
    meta: login_45email0yGzZxOn6YMeta || {},
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/login-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-recovery-password",
    path: "/auth/recovery-password",
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/recovery-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-registration-email",
    path: "/auth/registration-email",
    meta: registration_45email6nMzVSGThUMeta || {},
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/registration-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-registration-gender",
    path: "/auth/registration-gender",
    meta: registration_45gender4Ursnhzv7nMeta || {},
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/registration-gender.vue").then(m => m.default || m)
  },
  {
    name: "auth-registration-name",
    path: "/auth/registration-name",
    meta: registration_45namea5a8KGSpVpMeta || {},
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/registration-name.vue").then(m => m.default || m)
  },
  {
    name: "auth-video-chat",
    path: "/auth/video-chat",
    meta: video_45chat8kISj8y9i6Meta || {},
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/video-chat.vue").then(m => m.default || m)
  },
  {
    name: "auth-welcome",
    path: "/auth/welcome",
    meta: welcomeeJIVS6hoWlMeta || {},
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/auth/welcome.vue").then(m => m.default || m)
  },
  {
    name: "banned",
    path: "/banned",
    meta: bannedOWik3wfWuCMeta || {},
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/banned.vue").then(m => m.default || m)
  },
  {
    name: "cab",
    path: "/cab",
    meta: cabIbx7WyYTWjMeta || {},
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab.vue").then(m => m.default || m),
    children: [
  {
    name: "cab-blocklist",
    path: "blocklist",
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab/blocklist.vue").then(m => m.default || m)
  },
  {
    name: "cab-chat",
    path: "chat",
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab/chat.vue").then(m => m.default || m)
  },
  {
    name: "cab-invoice-history",
    path: "invoice-history",
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab/invoice-history.vue").then(m => m.default || m)
  },
  {
    name: "cab-private-call",
    path: "private-call",
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab/private-call.vue").then(m => m.default || m)
  },
  {
    name: "cab-profile-settings",
    path: "profile-settings",
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab/profile-settings.vue").then(m => m.default || m)
  },
  {
    name: "cab-profile",
    path: "profile",
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab/profile.vue").then(m => m.default || m)
  },
  {
    name: "cab-stories",
    path: "stories",
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab/stories.vue").then(m => m.default || m)
  },
  {
    name: "cab-top-profiles",
    path: "top-profiles",
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab/top-profiles.vue").then(m => m.default || m)
  },
  {
    name: "cab-video-chat",
    path: "video-chat",
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cab/video-chat.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cookie-policy",
    path: "/cookie-policy",
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "do-not-sell-my-personal-info",
    path: "/do-not-sell-my-personal-info",
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/do-not-sell-my-personal-info.vue").then(m => m.default || m)
  },
  {
    name: "double-connection",
    path: "/double-connection",
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/double-connection.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "purchase-terms",
    path: "/purchase-terms",
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/purchase-terms.vue").then(m => m.default || m)
  },
  {
    name: "subscription-terms",
    path: "/subscription-terms",
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/subscription-terms.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: () => import("/codebuild/output/src475477173/src/fe-monorepo/apps/mili/src/pages/terms-and-conditions.vue").then(m => m.default || m)
  }
]