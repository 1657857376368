import { uuid } from '@/shared/lib/uuid'

const FIELD_NAME = 'sessionId'

export function sessionIdGet(): string {
  let sessionId = sessionStorage.getItem(FIELD_NAME)
  if (!sessionId) sessionId = uuid()
  sessionStorage.setItem(FIELD_NAME, sessionId)
  return sessionId
}
