import type { IAnalyticsService, IAnalyticsAdapter } from './analytics.types'
import { AnalyticsService } from './analyticsService'

let analyticsCache: IAnalyticsService | undefined

const clients = new Map<string, IAnalyticsAdapter>()

export class Analytics {
  public static create(logging: boolean, windowId: string): IAnalyticsService {
    if (analyticsCache) return analyticsCache
    analyticsCache = new AnalyticsService(logging, windowId)
    return analyticsCache
  }

  public static get(): IAnalyticsService {
    if (!analyticsCache) throw new Error('Analytics not initialized')
    return analyticsCache
  }

  public static clients(): Map<string, IAnalyticsAdapter> {
    return clients
  }

  public static clientSet(name: string, client: IAnalyticsAdapter): void {
    if (clients.has(name)) return
    clients.set(name, client)
  }

  public static clientGet(name: string): IAnalyticsAdapter | undefined {
    return clients.get(name)
  }
}
