import type { CookiesGetAllItem } from './cookies.types'

export function set(name: string, value: string, days: number): void {
  let expires = ''
  const date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  expires = '; expires=' + date.toUTCString()
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

export function get(name: string): string | undefined {
  const nameEQ = name + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return undefined
}

export function getAll(): CookiesGetAllItem[] {
  const ca = document.cookie.split(';')
  const props = ca.map((val) => {
    const caArr = val.split('=')
    const name = caArr[0].replace(/^\s+/gi, '')
    return {
      name,
      value: caArr[1],
    }
  })
  return props
}

export function remove(name: string) {
  set(name, '', -100)
}
